<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn :to="{name: 'CabinetIndex'}" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Блоги</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn :to="{name: 'CabinetBlogAdd'}" text tile>
        Добавить
      </v-btn>
    </v-toolbar>

    <v-container>
      <v-btn color="primary" :to="{name: 'CabinetBlogAdd'}" dark fixed bottom right fab>
        <v-icon>$vuetify.icons.plus</v-icon>
      </v-btn>
      <v-row align="center">
          <no-content
          v-if="!loadPage && blogs.length == 0"
          route-name="CabinetBlogAdd"
          title="У вас еще нет блогов"
        ></no-content>
        <!-- <blog-card
          v-for="(item, index) in blogs"
          :key="item.id"
          :item="item"
          :index="index"
          @remove-blog-modal="deleteBlogModal"
        ></blog-card> -->

        <blog-card v-for="(blog, index) in blogs"
        @remove-blog-modal="deleteBlogModal"
        :key="blog.id" 
        :blog="blog" 
        :index="index" 
        :library="false" 
        :admin="true" 
        :cabinet="true">
        </blog-card>
      </v-row>
      <v-dialog v-model="dialogDeleteBlog" class="text-center" max-width="290">
        <v-card>
          <v-card-title class="headline justify-center">Вы уверены?</v-card-title>

          <v-card-text>Что хотите удалить данную запись.</v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="deleteBlog">Да</v-btn>

            <v-btn color="green darken-1" text @click="dialogDeleteBlog = false">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BlogCard from "../blog/BlogCard.vue";

export default {
  components: {
    BlogCard
  },
  data: () => ({
    dialogDeleteBlog: false,
    deleteData: null,
  }),
  methods: {
    getBlogs() {
      this.$store
        .dispatch("cabinet_blogs/getBlogs");
    },
    deleteBlog() {
      let app = this;
      app.dialogDeleteBlog = false;

      this.$store
        .dispatch("cabinet_blogs/dellBlog", {
          deleteData: app.deleteData,
        });

    },
    deleteBlogModal(data) {
      this.deleteData = data;
      this.dialogDeleteBlog = true;
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      blogs: "cabinet_blogs/blogs"
    })
  },
  mounted() {
    this.getBlogs();
  }
};
</script>
